import ApiService from '@zola-helpers/client/dist/es/http/api';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import type {
  WCartUpdateItemViewResponse,
  WGetCartViewResponse,
} from '@zola/svc-web-api-ts-client';
import { AppDispatch } from 'reducers/index';

import type { CamelCasedPropertiesDeep } from 'type-fest';
import {
  receivedCartSizeAction,
  requestCartAction,
  requestCartSizeAction,
  receiveCartAction,
} from './types/CartActionTypes';

export const getCartSize = () => (dispatch: AppDispatch) => {
  dispatch(requestCartSizeAction());

  return ApiService.get<CamelCasedPropertiesDeep<WGetCartViewResponse>>(
    `/website-nav/web-api/v1/cart`
  ).then((json) => {
    const length = json.data?.items?.length || 0;
    dispatch(receivedCartSizeAction(length));
  });
};

export const getCart = () => (dispatch: AppDispatch) => {
  dispatch(requestCartAction());

  return ApiService.get<CamelCasedPropertiesDeep<WGetCartViewResponse>>(
    `/website-nav/web-api/v1/cart`
  ).then((json) => {
    if (json.data) dispatch(receiveCartAction(json.data));
  });
};

export const removeItem = (id: string) => (dispatch: AppDispatch) => {
  dispatch(requestCartAction());

  return ApiService.delete<CamelCasedPropertiesDeep<WGetCartViewResponse>>(
    `/website-nav/web-api/v1/cart/item/id/${id}`
  ).then((json) => {
    if (json.data) dispatch(receiveCartAction(json.data));
  });
};

export const removeItems = (itemIds: string[]) => (dispatch: AppDispatch) => {
  dispatch(requestCartAction());

  return ApiService.post<CamelCasedPropertiesDeep<WGetCartViewResponse>>(
    `/website-nav/web-api/v1/cart/items`,
    { itemIds }
  ).then((json) => {
    if (json.data) dispatch(receiveCartAction(json.data));
  });
};

export const updateItemQuantity = (id: string, quantity: number) => (dispatch: AppDispatch) => {
  dispatch(requestCartAction());

  return ApiService.put<CamelCasedPropertiesDeep<WCartUpdateItemViewResponse>>(
    `/website-nav/web-api/v1/cart/item/id/${id}`,
    { quantity }
  ).then((json) => {
    if (json && json.data?.errorMessage) {
      const error = new Error(json.data.errorMessage);
      /** @ts-expect-error This should be ErrorWithResponse probably.  Are we using the response? probably not */
      error.response = json.data;
      dispatch(toastsActions.negative({ headline: json.data.errorMessage }));
    }

    if (json.data?.cart) dispatch(receiveCartAction(json.data.cart));
  });
};
